import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";

export default function Greeting() {
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1 className="greeting-text">
                {" "}
                {greeting.title}{" "}
                <span className="wave-emoji">&#x1F44B;</span>
              </h1>
              <p className="greeting-text-p subTitle">{greeting.subTitle}<span>&#x1F468;&#x200D;&#x1F4BB;</span></p>

              <SocialMedia />
              <div className="button-greeting-div">
                <Button text="Buy Me Coffee" newTab={true} href={"https://www.buymeacoffee.com/GhoudanAyoub"} />
                {/* <Button text="Contact me" href="#contact" /> */}
                <Button
                  text="See my resume"
                  newTab={true}
                  href={greeting.resumeLink}
                />
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
            <img
              alt="man at desk"
              src={require("../../assets/images/manOnTable.png")}
            />
          </div>
        </div>
      </div>
    </Fade>
  );
}
