import React from "react";
import "./ExperienceCard.css";
import {
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css';

export default function ExperienceCard({ experience }) {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentArrowStyle={{ borderRight: '7px solid  #1d1836' }}
      contentStyle={{ background: "#1d1836", color: "#FFFFFF", }}
      date={experience.date}
      icon={
        <img style={{ backgroundColor: "white" }} className="experience-roundedimg" src={experience.companylogo} alt={experience.company} />}
      iconStyle={{ background: "white", color: '#fff' }}
    >
      <div>
        <h3 className="text-white text-[24px] font-bold">{experience.role}</h3>
        <p
          className="text-secondary text-[16px] font-semibold"
          style={{ margin: 0 }}
        >
          {experience.company} <br /> {experience.date}
        </p>
      </div>

      <ul className="mt-5 list-disc ml-5 space-y-2">
        {experience.descBullets.map((point, index) => (
          <li
            key={`experience-point-${index}`}
            className="text-white-100 text-[14px] pl-1 tracking-wider"
          >
            {point}
          </li>
        ))}
      </ul>
    </VerticalTimelineElement>
  );
}
