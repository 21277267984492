/* Change this file to get your personal Portfolio */

import emoji from "react-easy-emoji";

// Your Summary And Greeting Section

const greeting = {
  username: "Ghoudan Ayoub",
  title: "Hi all, I'm Ayoub",
  subTitle: emoji(
    "Android Developer "
  ),
  resumeLink:
    "https://drive.google.com/file/d/1-ijUF_lnKt-lYoPD8Qjr_HT5ul1z4xNH/view?usp=share_link",
};

// Your Social Media Link

const socialMediaLinks = {
  github: "https://github.com/GhoudanAyoub",
  linkedin: "https://www.linkedin.com/in/ayoub-ghoudan-36a122161/",
  gmail: "ayoubghoudanos@gmail.com",
  facebook: null,
  twitter: null,
};

// Your Skills Section

const skillsSection = {
  title: "What i do",
  subTitle:
    "Managing the complete mobile development cycle including testing, integration and publishing.",
  skills: [
    emoji(
      "Designing, developing, debugging And diagnosing the Apps using Java, Kotlin And Flutter"
    ),
    emoji("Data pipeline for gathering and processing high volume of data collected from the users devices"),
    emoji("Implementing, optimizing and maintaining apps"),
    emoji(
      "Providing strong architectural designs."
    ),
  ],

  softwareSkills: [
    {
      skillName: "Android Studio",
      classname: "simple-icons:android",
      style: {
        backgroundColor: "transparent",
        color: "green"
      },
    },
    {
      skillName: "Java",
      classname: "logos:java",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Kotlin",
      classname: "simple-icons:kotlin",
      style: {
        backgroundColor: "white",
        color: "#D00000",
      },
    },
    {
      skillName: "Flutter",
      classname: "logos:flutter",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Dart",
      classname: "logos:dart",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "React Native",
      classname: "simple-icons:react",
      style: {
        color: "#61DAFB",
      },
    },
    {
      skillName: "NodeJS",
      classname: "logos:nodejs",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Php",
      classname: "logos:php",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Git",
      classname: "logos:git-icon",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "Postgres",
      classname: "logos:postgresql",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "FireBase",
      classname: "logos:firebase",
      style: {
        backgroundColor: "transparent",
      },
    },
    {
      skillName: "MySQL",
      classname: "simple-icons:mysql",
      style: {
        backgroundColor: "white",
        color: "#D00000",
      },
    },
    {
      skillName: "Adobe XD",
      classname: "simple-icons:adobexd",
      style: {
        backgroundColor: "transparent",
        color: 'orange'
      },
    },
    {
      skillName: "Figma",
      classname: "logos:figma",
      style: {
        backgroundColor: "transparent",
      },
    },
  ],
};

// Your top 3 work experiences

const workExperiences = {
  viewExperiences: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Android Developer",
      company: "Chari.co - YC S21 - Ecom and Fintech app for retailers in French-speaking Africa",
      companylogo: require("./assets/images/charilogo.png"),
      date: "Mars 2022 – Present",
      descBullets: [
        "Developed and maintained Android applications for chari.ma",
        "Collaborated with a diverse team, including designers, product managers, and backend developers, to ensure high-quality mobile app delivery",
        "Actively participated in all phases of the Agile Scrum methodology",
        "Conducted code reviews to maintain code quality and adherence to established guidelines",
        "Took charge of publishing versions on Google Play and managing the Firebase dashboard",
        "Utilized technologies such as Android, Kotlin, Firebase, Room, Retrofit, RestAPI, Coroutines, MVVM, Dagger-Hilt, and Git to accomplish tasks."
      ],
    },
    {
      role: "Android Developer",
      company: "Anfasolutions",
      companylogo: require("./assets/images/anfa.png"),
      date: "january 2022 – february 2022",
      descBullets: [
        "Participate in the development of a commercial mobile application for order management.",
        "Collaboration with a team including project managers and ERP developers to provide an accounting or business management system",
        "Technologies: Android, Kotlin, Firebase, Room, Retrofit, RestAPI, Coroutines, MVVM, Dagger-Hilt, Figma, Git, Microsoft Dynamics CRM, Power BI."
      ],
    },
    {
      role: "Mobile Developer",
      company: "Fiverr",
      companylogo: require("./assets/images/fiverrlogo.png"),
      date: "Dec 2020 – Present",
      descBullets: [
        "Developing Live streaming app using Flutter for Android and iOS. Live365 more than 50k Downloads",
        "Developing a food delivery app using Flutter for Android and iOS.",
        "Developing a restaurant reservation app using Flutter for Web Android and iOS. Lmaida",
        "Developing a kids streaming music app using Flutter for iOS.",
        "Developing Mobile application for consulting real estate projects. etc...",
      ],
    },
    {
      role: "Android Developer",
      company: "TreeProg",
      companylogo: require("./assets/images/treeproglogo.png"),
      date: "june 2019 – december 2021",
      desc:
        "Android developer in charge of all architecture decisions.",
      descBullets: [
        "Participate in the development of two mobile applications for taxi reservation.",
        "Participate in the development of a mobile application for real estate project consultation.",
        "Regular research and updating of the latest trends and technologies in Android development.",
        "Technologies : Microsoft Azure, Android, java, RxJava, Flutter, Nodejs, php, FireBase, Room, Retrofit, RestAPI, Moshi, MVVM, Dagger-Hilt, MapBox Map, Google Map, Git",
      ],
    },
    {}
  ],
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to false
  display: true, // Set false to hide this section, defaults to true
};

// Your certifications

const certifications = {
  certifications: [
    {
      title: "Honoris 21st Century Skills Certificate",
      subtitle: "- Honoris Online Academy",
      logo_path: "honoris.png",
      certificate_link:
        "https://certificate.bcdiploma.com/check/C3BE08D1E493AF123BD1441D4A93BD375F758BE5A4FB4E1CAAA185FEBAD45EEFVUd1RWdOS2MyWEsxanRzRzhtY2J0blFTK0pQd3F2L1J3eHIwYnFaTzJSUnlXU3l4",
      alt_name: "Honoris",
      color_code: "#FFFFFF",
      label: "Certificate"
    },
    {
      title: "Android",
      subtitle: "- LinkedIn Skill Assessment badge",
      logo_path: "liklogo.png",
      certificate_link:
        "https://www.linkedin.com/in/ayoub-ghoudan-36a122161/",
      alt_name: "Linkedin",
      color_code: "#FFFFFF",
      label: "Badge"
    },
    {
      title: "Android Development: Retrofit with Java",
      subtitle: "- Linkedin LEARNING",
      logo_path: "liklogo.png",
      certificate_link:
        "https://drive.google.com/file/d/1tyX0gYTSzaLkcdfFlonCEGnU5uak0DJs/view?usp=sharing",
      alt_name: "Linkedin",
      color_code: "#FFFFFF",
      label: "Certificate"
    },
    {
      title: "AutoCAD 2020 Essential Training",
      subtitle: "- Linkedin LEARNING",
      logo_path: "liklogo.png",
      certificate_link:
        "https://drive.google.com/file/d/1XarQqqNiqkRuPOy6J4zjf1FzSy7uOOQq/view?usp=sharing",
      alt_name: "Linkedin",
      color_code: "#FFFFFF",
      label: "Certificate"
    },
    {
      title: "Kali Linux, E.H and P.P",
      subtitle: "-  Ermin Kreponic, Aldin Omerdic",
      logo_path: "udemylogo.svg",
      certificate_link:
        "https://www.udemy.com/certificate/UC-FW0TSRUI/",
      alt_name: "Kali Linux",
      color_code: "#FFFFFF",
    },
  ],
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",

  blogs: [
    {
      url:
        "https://blog.bharatkammakatla.com/selenium-python-automation-framework-how-to-build-cke5vij9p012h9ds1fsaocyax",
      title: "How to build a Selenium Python Automation Framework",
      description:
        "Interested in building a test automation framework with selenium and python? If so, check out here !",
    },
  ],
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  number: "+212 639603352",
  email_address: "ayoubghoudanos@gmail.com",
};

export {
  greeting,
  socialMediaLinks,
  skillsSection,
  workExperiences,
  openSource,
  blogSection,
  contactInfo,
  certifications,
};
