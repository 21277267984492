import React from "react";
import "./WorkExperience.css";
import { workExperiences } from "../../portfolio";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import {
    VerticalTimelineElement,
    VerticalTimeline
} from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css';
import StarIcon from '@material-ui/icons/Star';

export default function WorkExperience() {
    if (workExperiences.viewExperiences) {
        return (
            <div id="experience">
                <div id="workExperience">
                    <h1 className="experience-heading">Experiences</h1>
                    <div>
                        <br />
                        <div className="experience-cards-div">
                            <div className="verticalTimeline">
                                <VerticalTimeline lineColor={"#1d1836"}>
                                    {workExperiences.experience.map((experience, index) => (
                                        //check  if last element in array
                                        index === workExperiences.experience.length - 1 ?
                                            <VerticalTimelineElement
                                                iconStyle={{ background: "#1d1836", color: '#fff' }}
                                                icon={<StarIcon />}
                                            />
                                            :
                                            <ExperienceCard key={index} experience={experience} />
                                    ))}
                                </VerticalTimeline>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return null;
}
